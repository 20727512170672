'use strict';

(function ($, window, document) {

  $(function () {


    // Slider
    (function () {
      if ($('.js_slider').length) {
        $('.js_slider').slick({
          fade: true,
          cssEase: 'linear',
          speed: 600,
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnHover: false,
          pauseOnFocus: false,
          infinite: true,
          dots: true,
          dotsClass: 'c-slider__dots',
          arrows: false
        });
      }
    })();


    // Fade in slide overlay and text
    (function () {

      const slideOverlay = {

        el: {
          $text: $('.c-slider__item__text'),
          $slide: $('.c-slider__item'),
          $pageOverlay: $('.o-page__overlay')
        },

        config: {
          fadeDelayOverlay: 1000,
          fadeDelayText: 1500,
          fadeDuration: 700
        },

        init() {
          this.fadeOutPageOverlay();
          if ($('.o-page--top').length) {
            this.fadeInText();
          }
          //this.fadeInOverlay();
        },

        fadeInText() {
          const _this = this;

          setTimeout(function () {
            $(_this.el.$text).addClass('c-slider__item__text__active');
          }, _this.config.fadeDelayText);

        },

        fadeInOverlay() {
          const _this = this;

          setTimeout(function () {
            $(_this.el.$slide).addClass('c-slider__item__overlay');
          }, _this.config.fadeDelayOverlay);

        },

        fadeOutPageOverlay() {
          const _this = this;

          $(_this.el.$pageOverlay).fadeOut(2000);

        }

      };

      slideOverlay.init();

    })();


    // Fade in hero text
    (function () {

      const heroText = {

        el: {
          $headline: $('.c-hero__headline'),
          $hero: $('.c-hero')
        },

        config: {
          fadeDelayOverlay: 1000,
          fadeDelayText: 1500,
          fadeDuration: 700
        },

        init() {
          this.fadeInHeadline();
        },

        fadeInHeadline() {
          const _this = this;

          setTimeout(function () {
            $(_this.el.$headline).addClass('c-hero__headline--active');
          }, _this.config.fadeDelayText);

        }

      };

      if ($('.o-page--sub').length) {
        heroText.init();
      }

    })();


    // Set SP slider height
    (function () {

      const sliderHeightFix = {

        el: {
          $slider: $('.js_slider'),
          $slide: $('.c-slider__item'),
          $page: $('.o-page')
        },

        init() {
          this.setHeight();
          this.resizeHandler();
        },

        setHeight() {
          const _this = this;

          let windowHeight = window.innerHeight;
          let sliderHeight = windowHeight - 47;

          _this.el.$slider.css('height', sliderHeight);
          _this.el.$slide.css('max-height', sliderHeight);
          _this.el.$page.css('padding-top', sliderHeight);
        },

        resizeHandler() {
          const _this = this;

          $(window).on('resize', function () {
            _this.setHeight();
          });
        }

      };

      if ($(window).width() < 768 && $('.js_slider').length) {
        sliderHeightFix.init();
      }

    })();


    // Set SP hero height
    (function () {

      const heroHeightFix = {

        el: {
          $hero: $('.js_hero'),
          $page: $('.o-page'),
          noOffsetClass: 'js_hero--no-offset'
        },

        conf: {
          arrowOffset: 47
        },

        init() {
          this.setHeight();
        },

        setHeight() {
          const windowHeight = window.innerHeight;
          let heroHeight = 0;

          if (this.el.$hero.hasClass(this.el.noOffsetClass)) {
            heroHeight = windowHeight;
          } else {
            heroHeight = windowHeight - this.conf.arrowOffset;
          }

          this.el.$hero.css('height', heroHeight);
          this.el.$page.css('padding-top', heroHeight);
        },

      };

      if ($(window).width() < 768 && $('.js_hero').length) {
        heroHeightFix.init();
      }

    })();


    // Sticky global navigation
    (function () {

      const stickyNav = {

        el: {
          $header: $('.c-header'),
          stickyHeaderClass: 'c-header__sticky'
        },

        config: {
          stickyEnabled: false,
          stickyCreated: false,
          stickyHeight: 89,
          stickyHeightNarrow: 91
        },

        init() {
          this.scroll();
        },

        scroll() {
          const _this = this;
          let scrollPos;
          let stickyPos;

          $(window).scroll(function () {

            let windowWidth = $(window).width();

            if (windowWidth > 767) {

              const offset = $(window).height() / 2;
              scrollPos = $(window).scrollTop();
              stickyPos = _this.el.$header.offset().top + offset;

              if (scrollPos > stickyPos && _this.config.stickyEnabled !== true) {
                if (_this.config.stickyCreated !== true) {

                  _this.createStickyNav();
                  _this.fadeIn();

                  _this.config.stickyCreated = true;
                } else {
                  _this.fadeIn();
                }

                _this.config.stickyEnabled = true;
              }

              if (scrollPos < stickyPos && _this.config.stickyEnabled !== false) {
                _this.fadeOut();

                _this.config.stickyEnabled = false;
              }

            }

          });

        },

        createStickyNav() {
          this.el.$header.clone().addClass(this.el.stickyHeaderClass).prependTo('body');
        },

        slideDown() {
          $(`.${this.el.stickyHeaderClass}`).animate({
            top: '0'
          }, 500);
        },

        slideUp() {
          if (($(window).width()) > 950) {
            $(`.${this.el.stickyHeaderClass}`).animate({
              top: `-${this.config.stickyHeight}`
            }, 500);
          } else {
            $(`.${this.el.stickyHeaderClass}`).animate({
              top: `-${this.config.stickyHeightNarrow}`
            }, 500);
          }
        },

        fadeIn() {
          $(`.${this.el.stickyHeaderClass}`).animate({
            opacity: '1'
          }, 500);
        },

        fadeOut() {
          $(`.${this.el.stickyHeaderClass}`).animate({
            opacity: '0'
          }, 500);
        }

      };

      // Only enable the sticky dropdown navigation on the top page
      if ($('.c-header').length) {
        stickyNav.init();
      }

    })();


    // Page navigation
    (function () {

      const slideDownLinks = {

        el: {
          $links: $('.js_link'),
          $linksSPTop: $('.js_link-sp-top')
        },

        config: {
          offset: 20,
          animationSpeed: 700
        },

        init() {
          this.clickHandler();
          //this.clickHandlerSPTop();
        },

        clickHandler() {
          const _this = this;

          this.el.$links.on('click', function (e) {
            e.preventDefault();

            const target = $(this).attr('href');

            // let headerHeight = 0;
            // if ($('.c-header').css('display') !== 'none') {
            //   headerHeight = $('.c-header').height();
            // }

            const offset = $(target).offset().top;

            $('html,body').animate({ scrollTop: offset }, _this.config.animationSpeed);

            return 0;
          });
        },

        clickHandlerSPTop() {
          const _this = this;

          this.el.$linksSPTop.on('click', function (e) {
            e.preventDefault();

            const target = $(this).attr('href');

            // let headerHeight = 0;
            // if ($('.c-header').css('display') !== 'none') {
            //   headerHeight = $('.c-header').height();
            // }

            const $parallaxSection = $(target).parent().parent();

            const inlineCSS = $parallaxSection.attr('style');

            const indexFrom = inlineCSS.indexOf('translate');
            const indexTo = inlineCSS.indexOf('%)');

            const cssTranslate = inlineCSS.substr(indexFrom, indexTo - indexFrom + 2);
            const translateY = parseFloat(cssTranslate.split(',')[1]);
            const parallaxOffset = $parallaxSection.height() * (Math.abs(translateY) / 100);

            const offset = $(target).offset().top + ($parallaxSection.height() * 0.008);

            $('html,body').animate({ scrollTop: offset }, _this.config.animationSpeed);

            return 0;
          });
        }

      };

      if ($('.js_link').length) {
        slideDownLinks.init();
      }

    })();


    // Global navigation SP
    (function () {

      const siteNav = {

        el: {
          $toggle: $('.js_nav-toggle'),
          $nav: $('.js_nav'),
          $overlay: $('.js_nav-overlay')
        },

        config: {
        },

        init() {
          this.clickHandler();
        },

        clickHandler() {
          const _this = this;

          this.el.$toggle.on('click', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');
            _this.toggleNav();
            _this.toggleOverlay();

            return 0;
          });
        },

        toggleNav() {
          this.el.$nav.toggleClass('active');
          this.el.$toggle.parent().toggleClass('active');
        },

        toggleOverlay() {
          this.el.$overlay.toggleClass('active');
        }

      };

      if ($('.js_nav-toggle').length) {
        siteNav.init();
      }

    })();


    // Menu accordion (PC)
    (function () {

      const menuAccordionPC = {

        el: {
          $links: $('.js_menu-link-pc'),
          $content: $('.js_menu-content'),
          contentClass: '.c-menu__content',
          $footnote: $('.c-menu__content__footnote'),
          $closeButtons: $('.js_menu-close')
        },

        conf: {
          specialAnchors: [
            'lunch',
            'dinner',
            'drink'
          ]
        },

        init() {
          const ww = $(window).width();
          if (ww > 767) {
            this.urlHandler();
          }
          this.clickHandlerOpen();
          this.resizeHandler();
        },

        clickHandlerOpen() {
          const _this = this;

          this.el.$links.on('click', function (e) {
            e.preventDefault();

            _this.el.$content.removeClass('c-menu--active-pc');

            const target = $(this).attr('href');

            if (!$(target).hasClass('c-menu--active')) {
              _this.openContent(target);
              _this.setActiveLink(this);
            }

            return 0;
          });
        },

        openContent(target, callback) {
          this.el.$content.removeClass('c-menu--active');
          $(target).addClass('c-menu--active');
          this.setContentPadding();

          if (typeof callback == 'function') {
            callback();
          }
        },

        setActiveLink(link) {
          this.el.$links.removeClass('active');
          $(link).addClass('active');
        },

        urlHandler() {
          const _this = this;

          const anchor = this.getURLAnchor();
          const tabAnchors = this.getTabAnchors();

          if ($.inArray(anchor, tabAnchors) >= 0) {

            // Disable Browser's scroll position restore
            if ('scrollRestoration' in history) {
              history.scrollRestoration = 'manual';
            }

            const link = `a[href="#${anchor}"].js_menu-link-pc`;
            const content = `#${anchor}`;

            _this.el.$content.removeClass('c-menu--active-pc');

            _this.openContent(content, function () {
              setTimeout(function () {
                _this.goToContent(link);
              }, 300);
            });
            _this.setActiveLink(link);
          }
        },

        getURLAnchor() {
          const anchor = window.location.hash.substr(1);
          return anchor;
        },

        getTabAnchors() {
          const anchors = [];
          this.el.$links.find('a').each(function () {
            const anchor = $(this).attr('href').replace('#', '');
            anchors.push(anchor);
          });

          return anchors.concat(this.conf.specialAnchors);
        },

        goToContent(target) {
          const offset = $(target).offset().top - 73;
          $('html,body').animate({ scrollTop: offset }, 0);
        },

        setContentPadding() {
          const _this = this;
          _this.el.$footnote.each(function() {
            const footnoteHeight = $(this).outerHeight(true);
            const $content = $(this).parent().find(_this.el.contentClass);

            $content.css('padding-bottom', footnoteHeight + 'px');
          });
        },

        resizeHandler() {
          var _this = this;
          var resizeTimer;

          $(window).on('resize', function(e) {

            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {

              _this.setContentPadding();

            }, 10);

          });
        }

      };

      if ($('.js_menu-link-pc').length) {
        menuAccordionPC.init();
      }

    })();


    // Menu accordion (SP)
    (function () {

      const menuAccordionSP = {

        el: {
          $links: $('.js_menu-link-sp'),
          $content: $('.js_menu-content'),
          $closeButtons: $('.js_menu-close')
        },

        conf: {
          openAnimationTime: 800,
          closeAnimationTime: 1600,
          specialAnchors: [
            'lunch',
            'dinner',
            'drink'
          ]
        },

        init() {
          const ww = $(window).width();
          if (ww < 768) {
            this.urlHandler();
          }
          this.clickHandlerOpen();
          this.clickHandlerClose();
        },

        clickHandlerOpen() {
          const _this = this;

          this.el.$links.on('click', function (e) {
            e.preventDefault();

            _this.el.$content.removeClass('c-menu--active-pc');

            const target = $(this).attr('href');

            if (!$(target).hasClass('c-menu--active')) {
              _this.openContent(target);
              _this.setActiveLink(this);
              _this.replaceImage(this);
            } else {
              _this.closeContent($(target));
            }

            return 0;
          });
        },

        clickHandlerClose() {
          const _this = this;

          this.el.$closeButtons.on('click', function (e) {
            e.preventDefault();

            const target = $(this).attr('href');
            const link = $(target).prev();

            _this.closeContent($(target));

            return 0;
          });
        },

        openContent(target, callback) {
          const $target = $(target);
          this.el.$content.removeClass('c-menu--active');
          $target.addClass('c-menu--active');

          this.el.$content.hide();

          if (typeof callback != 'function') {
            const rate = this.conf.openAnimationTime; // pixels per second
            const height = $target.height();
            const ms = height / rate * 1000;
            $target.stop(true, true).slideDown(ms, 'swing');
          } else {
            $target.show(function () {
              callback();
            });
          }
        },

        // closeAllContent() {
        //   const _this = this;
        //
        //   this.el.$content.removeClass('c-menu--active');
        //   this.el.$content.hide();
        //   this.resetImages();
        // },

        closeContent($content, closeButton) {
          const _this = this;

          $content.removeClass('c-menu--active');

          if (!closeButton) {
            // const rate = this.conf.closeAnimationTime; // pixels per second
            // const height = $content.height();
            // const ms = height / rate * 1000;
            $content.stop(true, true).slideUp(400, 'swing', function () {
              _this.resetImages();
              _this.removeActiveLinks();
            });
          } else {
            $content.hide();
            _this.resetImages();
          }
        },

        setActiveLink(link) {
          this.el.$links.removeClass('active');
          $(link).addClass('active');
        },

        removeActiveLinks() {
          this.el.$links.removeClass('active');
        },

        replaceImage(link) {
          // Reset button images
          this.resetImages();

          // Switch button image
          if ($(link).hasClass('active')) {
            $(link).find('img').attr('src', function (index, attr) {
              return attr.replace('.png', '--active.png');
            });
          } else {
            $(link).find('img').attr('src', function (index, attr) {
              return attr.replace('--active.png', '.png');
            });
          }
        },

        resetImages() {
          this.el.$links.find('img').attr('src', function (index, attr) {
            return attr.replace('--active.png', '.png');
          });
        },

        urlHandler() {
          const _this = this;

          const anchor = this.getURLAnchor();
          const tabAnchors = this.getTabAnchors();

          if ($.inArray(anchor, tabAnchors) >= 0) {

            // Disable Browser's scroll position restore
            if ('scrollRestoration' in history) {
              history.scrollRestoration = 'manual';
            }

            const link = `a[href="#${anchor}"].js_menu-link-sp`;
            const content = `#${anchor}`;

            _this.el.$content.removeClass('c-menu--active-pc');

            _this.openContent(content, function () {
              _this.goToContent(link);
            });
            _this.setActiveLink(link);
            _this.replaceImage(link);
          }
        },

        getURLAnchor() {
          const anchor = window.location.hash.substr(1);
          return anchor;
        },

        getTabAnchors() {
          const anchors = [];
          this.el.$links.find('a').each(function () {
            const anchor = $(this).attr('href').replace('#', '');
            anchors.push(anchor);
          });

          return anchors.concat(this.conf.specialAnchors);
        },

        goToContent(target) {
          const offset = $(target).offset().top;
          $('html,body').animate({ scrollTop: offset }, 0);
        }

      };

      if ($('.js_menu-link-sp').length) {
        menuAccordionSP.init();
      }

    })();


    // Instagram feed
    (function () {

      const userFeed = new Instafeed({
        get: 'user',
        limit: 4,
        resolution: 'low_resolution',
        userId: 4481309720,
        accessToken: '4481309720.1677ed0.4710703901c046bfa090cfb408741eb9',
        template: '<a class="c-footer-instagram__images__img" href="{{link}}" target="_blank"><img src="{{image}}" /></a>'
      });
      if ($('#instafeed').length) {
        userFeed.run();
      }

    })();


  });

})(window.jQuery, window, document);
